.container {
    /* scroll-snap-type: y mandatory;
    overflow-y: scroll; */
    height: auto;
    overflow: hidden;
}

.main-home-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* scroll-snap-align: start; */
}

.main-home-container img {
    position: absolute;
    z-index: 12;
    width: min(550px, 75%);
}

.main-home-container video {
    position: absolute;
    height: 100vh;
    width: auto;
    z-index: 9;



}

.background {
    position: absolute;
    background-image: url(../../Media/concrete-wall-2.png);
    opacity: 0.5;
    height: 100vh;
    width: 100vw;
    z-index: 10;


}

.video-tint {
    position: absolute;
    mix-blend-mode: multiply;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.23);
    width: 100vw;
    height: 100vh;
}

.noise-filter {
    position: absolute;
    z-index: 10;
    top: -50px;
    left: -50px;
    width: calc(100% + 50px);
    height: calc(100% + 50px);
    background: repeating-linear-gradient(#111, #111 50%, #fff 50%, #fff);
    background-size: 5px 5px;
    mix-blend-mode: multiply;
    opacity: 0.3;
    filter: url(#noise);
}

.svgs {
    width: 0;
    height: 0;
}

.subscribe-window {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 115vh;
    width: 100vw;
    margin-top: -3px;
    background-color: rgb(0, 0, 0);
    z-index: 20;
    scroll-snap-align: start;
    border-block-start: 3px solid;
    padding-block-start: 10px;
    border-image-source: linear-gradient(90deg, #30cfd0, #330867);
    border-image-slice: 1;


}

.card-sub {
    width: min(100%, 60rem);
    height: 30rem;
    border-radius: 5px;
    overflow: hidden;
    display: flex;


    /* justify-content: center;
    align-items: center; */


}

.texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    width: 50%;
    /* height: 10rem; */
    background-color: white;
    padding-left: 2rem;

}

.texts h1 {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    margin-top: 0;

}

.texts h2 {
    font-size: clamp(1rem, 2vw, 2rem);
    color: gray;
    margin-bottom: 0;
    padding-top: 3rem;
}

.input-component {
    width: 25rem;
    height: 2.5rem;

    margin-right: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
}

.input-component input {
    width: 75%;
    margin-right: 10px
}

.input-component button {
    width: 25%;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;

}


.imgs {
    width: 50%;
    position: relative;
    overflow: hidden;
}

.imgs::before {
    content: '';
    position: absolute;
    left: -6.1em;
    top: 50%;
    transform: translateY(-50%) rotate(20deg);
    background: white;
    width: 40%;
    height: 140%;

}

.fade-anim {
    animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


.imgs img {
    width: 30rem;
    height: 30rem;
    object-fit: cover;
    border-radius: 5px;
    /* overflow: hidden; */
    /* animation: fadeIn 5s; */
    /* animation: fadeAnim 0.5s ease-out reverse; */
}


/* __vv__MEDIA QUERIES__vv__*/

/*_____MOBILE DEVICE_____*/

/*______________________________________ LANDSCAPE ______________________________________*/
@media screen and (width < 930px) and (orientation: landscape) {
    .main-home-container img {
        width: 40%
    }

    .main-home-container video {
        height: 100vh;
        width: auto;
    }

    .subscribe-window {

        height: auto;
    }

    .card-sub {
        display: block;
        margin-top: 80px;
        height: 45rem;
        width: min(25rem, 75%);
    }

    .imgs img {
        height: 65vh;
        width: 100%;
    }

    .texts {
        height: 15rem;
        width: 100%;
        padding-left: 1rem;
        position: relative;
        z-index: 1;
    }

    .imgs {
        width: 100%;
    }

    .imgs::before {
        width: 120%;
        height: 40%;
        top: -5em;
        left: 50%;

        transform: translateX(-50%) rotate(10deg);
    }

    .texts h2 {
        padding-top: 1.5rem;
    }

    .texts h1 {
        padding-right: 2rem;

    }

    .input-component {
        width: 90%;
        height: auto;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .input-component input {
        width: 90%;
        height: 35px;
        margin-right: 0;
        margin-bottom: 10px;
        border-radius: 3px;
        text-align: center;
        font-size: medium;
        box-sizing: border-box;

    }

    .input-component button {
        width: 90%;
        height: 35px;
        font-weight: 600;
        font-size: large;
        box-sizing: border-box;
    }

}

/*______________________________________ PORTRAIT ______________________________________*/
@media screen and (width < 930px) and (orientation: portrait) {
    .card-sub {
        display: block;
        height: auto;
        width: min(25rem, 75%);
    }

    .texts {
        height: 15rem;
        width: 100%;
        padding-left: 1rem;
        position: relative;
        z-index: 1;
    }

    .imgs {
        width: 100%;
    }

    .imgs img {
        height: 50vh;
        width: 100%;


    }

    .imgs::before {
        width: 120%;
        height: 50%;
        top: -5em;
        left: 50%;
        transform: translateX(-50%) rotate(10deg);
    }

    .texts h2 {
        padding-top: 1.5rem;
    }

    .texts h1 {
        padding-right: 2rem;

    }

    .input-component {
        width: 90%;
        height: auto;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .input-component input {
        width: 90%;
        height: 35px;
        margin-right: 0;
        margin-bottom: 10px;
        border-radius: 3px;
        text-align: center;
        font-size: medium;
        box-sizing: border-box;

    }

    .input-component button {
        width: 90%;
        height: 35px;
        font-weight: 600;
        font-size: large;
        box-sizing: border-box;
    }



}

/*_____TABLET DEVICE_____*/
@media screen and (width > 767px) and (width < 1182px) {
    .main-home-container video {
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }

}

/*_____DESKTOP DEVICE_____*/
@media screen and (width > 1182px) {
    .main-home-container video {
        height: auto;
        width: 100vw;
    }
}

/* __^^__MEDIA QUERIES__^^__*/