footer {
    width: 100vw;
    height: 40vh;
    background-color: rgb(235, 235, 235);
    position: relative;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

footer img {
    width: min(150px, 25vw, 200px)
}

.contact a {
    color: rebeccapurple;
    text-decoration: none;
}

.contact h2 {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
}

.contact h3 {
    font-size: clamp(1rem, 2vw, 1.2rem);
}

.socials {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.socials img {
    width: 5vw;
    place-content: center;
}

.socials .icons {
    size: 2rem;
    display: flex;
    flex-wrap: nowrap;

}

.socials .icons a {
    size: 0rem;
    display: flex;
    flex-wrap: nowrap;

}

.socials .icons a {
    margin: 1em;

}

.footer-main {
    width: 100vw;
    height: 40vh;
    /* overflow: hidden; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

}

.footer-copy p {
    margin-top: -45px;
    color: gray;
    /* margin: 0; */
    font-size: clamp(.7rem, 2vw, 1rem);
}

.IG-tag {
    scale: 80%;
    border-radius: 50px;
    border: double 2px transparent;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(242, 242, 242)), linear-gradient(90deg, #30cfd0, #330867);
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: rgb(91, 91, 91);
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.5);
}

.IG-tag a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    text-decoration: none;
    color: black;
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;

}

.IG-tag h3 {}



/* __vv__MEDIA QUERIES__vv__*/

/*_____MOBILE DEVICE_____*/
/*______________________________________ LANDSCAPE ______________________________________*/
@media screen and (width < 930px) and (orientation: landscape) {

    footer {
        height: 110vh;
    }

    .footer-main {
        height: 91%;


        flex-direction: column;
        justify-content: space-evenly;

        flex-wrap: nowrap;

    }

    .IG-tag {
        scale: 65%;
    }

    .footer-copy p {
        margin-top: 0;
        /* margin: 0; */
        font-size: clamp(.7rem, 2vw, 1rem);
    }

    .contact {
        text-align: center;
    }

    /* footer img {
        margin-top: 70px;
    } */

}

/*______________________________________ PORTRAIT ______________________________________*/
@media screen and (width < 930px) and (orientation: portrait) {

    /* .contact h2 {
        font-size: clamp(1.5rem, 3vw, 2.5rem); 
    } */

    .footer-main {
        height: auto;

        flex-direction: column;
        justify-content: space-evenly;

        flex-wrap: nowrap;

    }

    .IG-tag {
        scale: 65%;
    }

    footer {
        height: 60vh;
    }

    .footer-copy p {
        margin-top: 0;
        /* margin: 0; */
        font-size: clamp(.7rem, 2vw, 1rem);
    }

    .contact {
        text-align: center;
    }

    /* footer img {
        margin-top: 70px;
    } */

}

/*_____TABLET DEVICE_____*/
@media screen and (width > 767px) and (width < 1023px) and (orientation: portrait) {
    footer {
        height: 70vh;
    }
}

/*_____DESKTOP DEVICE_____*/
@media screen and (width > 1900px) {}

/* __^^__MEDIA QUERIES__^^__*/